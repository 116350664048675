<div
  *ngIf="(tags()?.length > 0)"
  class="tags-container"
>
  <div class="tags">
    @for (tag of tags(); track tag.id) {
    <!-- -->
    <tag [tag]="tag"></tag>
    }
  </div>
</div>
