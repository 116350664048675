<mat-icon
  [style.background]="color()"
  class="tag-ico"
  >{{tag().icon}}</mat-icon
>
<span
  class="tag-title"
  *ngIf="!isHideTitle()"
  >{{ tag().title }}</span
>
