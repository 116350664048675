<!-- TODO make this work -->
<!--<mat-icon matSuffix="">{{formControl.value}}</mat-icon>-->

<mat-icon>{{ formControl.value }}</mat-icon>

<input
  [ngModel]="formControl.value"
  (ngModelChange)="onInputValueChange($event)"
  [formlyAttributes]="field"
  [matAutocomplete]="auto"
  matInput
  type="text"
/>
<!--<span matPrefix>+1 &nbsp;</span>-->

<mat-autocomplete
  #auto="matAutocomplete"
  (optionSelected)="onIconSelect($event.option.value)"
  [autoActiveFirstOption]="false"
>
  <mat-option
    *ngFor="let icon of (filteredIcons); trackBy: trackByIndex"
    [value]="icon"
  >
    <mat-icon>{{icon}}</mat-icon>
    <span>{{icon}}</span>
  </mat-option>
</mat-autocomplete>
