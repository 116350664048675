<div
  class="time"
  (click)="isForDayMode.set(!isForDayMode())"
>
  @if (!isForDayMode()) {
  <mat-icon>alarm</mat-icon>
  <span>{{ time() }}</span>
  <!---->
  } @else {
  <mat-icon>today</mat-icon>
  <span>{{ date()|date:'shortDate' }} </span>
  <!---->
  }
</div>

@if (!isEditMode()) {
<div class="msg">
  <mat-icon>add</mat-icon>
  click to create task
</div>
}

<textarea
  (blur)="onBlur()"
  (keydown)="onKeyDown($event)"
  #textAreaElement
></textarea>
