/* eslint-disable @typescript-eslint/no-duplicate-enum-values */

export enum MODEL_VERSION {
  TASK = 3.6,
  // needs to be always the same as TASK !!!
  TASK_ARCHIVE = 3.5,
  PROJECT = 6.14,
  GLOBAL_CONFIG = 3.4,
  METRIC = 1.0,
  SIMPLE_COUNTER = 2.0,
  NOTE = 1.0,
  TAG = 1.0,
  TASK_REPEAT = 1.43,

  // eslint-disable-next-line @typescript-eslint/naming-convention
  ___NOT_USED_YET___ = 0,
}
